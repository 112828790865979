import { APIResponse, Cookies, VerifiedUserResponse } from "../../types/types";
import env from "#env";
import { getHeaders } from "#shared/components/api/utils";
import { get } from "#shared/components/api/http";

interface VerifyJWTArgs {
  cookie?: Cookies;
  role?: number;
}

async function verifyJWT(
  args?: VerifyJWTArgs
): Promise<APIResponse<VerifiedUserResponse>> {
  const url = new URL(`${env.VITE_BS_API_BASE_URL}/login/verify.html`);
  if (typeof args?.role === "number") {
    url.searchParams.append("role", args.role.toString());
  }

  // WARNING: only use for local debugging
  if (env.VITE_DISABLE_JWT_CHECK === "true") {
    return {
      status: "success",
      code: 200,
      data: {
        verified: true,
      },
    };
  }

  return get<APIResponse<VerifiedUserResponse>>(url.toString(), {
    headers: getHeaders(args?.cookie),
    credentials: "include",
  });
}

export default verifyJWT;
