import env from "#env";
import createApi from "../../../createApi";
import {
  AssignPositionRequest,
  Cookies,
  GetMyOrganisationPositionsResponse,
  GetTeamMembersResponse,
  InviteTeamMembersRequest,
  InviteTeamMembersResponse,
  OrganisationPosition,
  OrganisationPositionStatus,
  RemoveTeamMemberRequest,
  ResendInviteRequest,
} from "../../types/types";
import { getHeaders } from "#shared/components/api/utils";
import { QUERY_TAGS } from "#constants/query";
import { appBaseQuery } from "#features/common/baseQuery";
import { client } from "#apis/axios";
import { AxiosHeaders } from "axios";

export const organisationPositionsApi = createApi({
  reducerPath: "organisationPositions",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/organisation-positions/`,
  }),
  tagTypes: [QUERY_TAGS.MyPositions, QUERY_TAGS.TeamMembers],
  endpoints: (builder) => ({
    fetchMyOrganisationPositions: builder.query<
      GetMyOrganisationPositionsResponse,
      { cookie?: Cookies } | undefined
    >({
      queryFn: async (args) => {
        try {
          const response = await client.get(
            `${env.VITE_API_BASE_URL}/community/organisation-positions/my-positions`,
            {
              headers: getHeaders(args?.cookie) as AxiosHeaders,
            }
          );

          const filteredData =
            (response.data as GetMyOrganisationPositionsResponse)?.filter(
              (position) => !position.organisation.isPersonalOrganisation
            ) || [];

          return response.data ? { data: filteredData } : { data: [] };
        } catch (error) {
          console.error(error);
          return { data: [] };
        }
      },
      serializeQueryArgs: () => QUERY_TAGS.MyPositions,
      providesTags: [QUERY_TAGS.MyPositions],
    }),
    fetchTeamMembers: builder.query<OrganisationPosition[], number>({
      query: (organisationId) => ({
        url: `organisation/${organisationId}`,
        credentials: "include",
      }),
      transformResponse: (response: GetTeamMembersResponse) => {
        const mappedPositions = response.data.map((position) => ({
          position: position.organisationPositions.id,
          user: {
            ...position.users,
            status: position.users.invitedDate
              ? OrganisationPositionStatus.Registered
              : OrganisationPositionStatus.Invited,
          },
        }));

        return mappedPositions;
      },
      keepUnusedDataFor: 60,
      providesTags: (_result, _error, organisationId) => [
        { type: QUERY_TAGS.TeamMembers, id: organisationId },
      ],
    }),
    inviteTeamMembers: builder.mutation<
      InviteTeamMembersResponse,
      InviteTeamMembersRequest
    >({
      query: (body) => ({
        url: "invite",
        method: "POST",
        body,
        credentials: "include",
      }),
      invalidatesTags: [QUERY_TAGS.TeamMembers],
    }),
    assignPosition: builder.mutation<void, AssignPositionRequest>({
      query: (body) => ({
        url: "assign-position",
        method: "PUT",
        body,
        credentials: "include",
      }),
      invalidatesTags: [QUERY_TAGS.TeamMembers],
    }),
    resendInvite: builder.mutation<void, ResendInviteRequest>({
      query: (body) => ({
        url: "resend-invite",
        method: "POST",
        body,
        credentials: "include",
      }),
      invalidatesTags: [QUERY_TAGS.TeamMembers],
    }),
    removeTeamMember: builder.mutation<void, RemoveTeamMemberRequest>({
      query: (body) => ({
        url: "delete-position",
        method: "DELETE",
        body,
        credentials: "include",
      }),
      invalidatesTags: [QUERY_TAGS.TeamMembers],
    }),
  }),
});

export const {
  useFetchMyOrganisationPositionsQuery,
  useFetchTeamMembersQuery,
  useInviteTeamMembersMutation,
  useAssignPositionMutation,
  useResendInviteMutation,
  useRemoveTeamMemberMutation,
} = organisationPositionsApi;
