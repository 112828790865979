import createApi from "../../../createApi";
import env from "#env";
import { getHeaders } from "#shared/components/api/utils";
import { QUERY_TAGS } from "#constants/query";
import {
  DeleteBriefResponse,
  DeleteBriefsRequest,
  GetBriefExportUrlRequest,
  GetBriefExportUrlResponse,
  ReportBriefRequest,
} from "#customTypes/brief";
import { ExportBriefAvailableOptions } from "#constants/export";
import { channelsApi } from "#features/channel/channelsAPI";
import { appBaseQuery } from "#features/common/baseQuery";

export interface FeedFiltersProps {
  onlyPrivate: boolean;
  channelRef: string | undefined;
}

export const feedApi = createApi({
  reducerPath: "feedSlice",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/briefs/`,
  }),
  tagTypes: [QUERY_TAGS.Briefs, QUERY_TAGS.Brief],
  endpoints: (builder) => ({
    fetchBriefExportAvailableOptions: builder.query<ExportBriefAvailableOptions, string>({
      query: (briefRef) => ({
        url: `${briefRef}/check-redirect`,
        headers: getHeaders(),
        credentials: "include",
      }),
    }),
    fetchBriefExportUrl: builder.query<
      GetBriefExportUrlResponse,
      GetBriefExportUrlRequest
    >({
      query: ({ briefRef, type }) => ({
        url: `${briefRef}/redirect`,
        headers: getHeaders(),
        params: { type },
        credentials: "include",
      }),
    }),
    reportBrief: builder.mutation<void, ReportBriefRequest>({
      query: (body) => ({
        url: `report`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),
    deleteBriefs: builder.mutation<DeleteBriefResponse, DeleteBriefsRequest>({
      query: (body) => ({
        url: `delete-batch`,
        method: "POST",
        body,
        credentials: "include",
      }),
      async onQueryStarted(_body, { dispatch, queryFulfilled }) {
        await queryFulfilled;

        dispatch(
          channelsApi.util.invalidateTags([
            QUERY_TAGS.Channel,
            QUERY_TAGS.ChannelAdminBriefs,
          ])
        );
      },
    }),
  }),
});

export const {
  useFetchBriefExportAvailableOptionsQuery,
  useFetchBriefExportUrlQuery,
  useReportBriefMutation,
  useDeleteBriefsMutation,
} = feedApi;
