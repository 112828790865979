import {
  buildCreateApi,
  coreModule,
  reactHooksModule,
} from "@reduxjs/toolkit/query/react";

export default buildCreateApi(
  coreModule(),
  reactHooksModule({
    unstable__sideEffectsInRender: true,
  })
);
