import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { GodViewState } from "../../types/state";
import { RootState } from "../../store";
import { AdminOrganisation } from "#customTypes/admin";
import { GOD_VIEW_STORAGE_KEY } from "#constants/common";

const initialState = {
  isModalOpen: false,
  selectedOrganisation: undefined,
} as GodViewState;

const godViewSlice = createSlice({
  name: "godView",
  initialState,
  reducers: {
    setGodViewModal(state, action: PayloadAction<boolean>) {
      state.isModalOpen = action.payload;
      return state;
    },
    setGodView(state, action: PayloadAction<AdminOrganisation>) {
      state.selectedOrganisation = action.payload;
      state.isModalOpen = false;
      sessionStorage.setItem(GOD_VIEW_STORAGE_KEY, JSON.stringify(action.payload));
      return state;
    },
    removeGodView(state, action: PayloadAction<number | undefined>) {
      if (action?.payload && action.payload !== state.selectedOrganisation?.id) {
        return state;
      }

      state.selectedOrganisation = undefined;
      sessionStorage.removeItem(GOD_VIEW_STORAGE_KEY);
      return state;
    },
    updateGodView(state, action: PayloadAction<Partial<Omit<AdminOrganisation, "id">>>) {
      if (!state.selectedOrganisation) {
        return state;
      }

      state.selectedOrganisation = {
        ...state.selectedOrganisation,
        ...action.payload,
      };
      sessionStorage.setItem(
        GOD_VIEW_STORAGE_KEY,
        JSON.stringify(state.selectedOrganisation)
      );
      return state;
    },
  },
});

export default godViewSlice.reducer;
export const { setGodViewModal, setGodView, removeGodView, updateGodView } =
  godViewSlice.actions;
export const selectGodView = (state: RootState) => state.godView;
