export type QueryParams = Record<string, string | number | boolean | undefined | null>;

export function objectToGetParams(object: QueryParams | undefined) {
  if (!object) {
    return "";
  }

  const params = Object.entries(object)
    .filter(([, value]) => value !== undefined && value !== null)
    .map(
      ([key, value]) => `${encodeURIComponent(key)}=${encodeURIComponent(String(value))}`
    );

  return params.length > 0 ? `?${params.join("&")}` : "";
}

export const appendHttpsToUrl = (url: string) => {
  const regex = /(https|http):\/\//;
  return `https://${url.replace(regex, "")}`;
};

export const getEnvironmentUrl = (url: string) => {
  const currTLD = `.${window.location.hostname.split(".").pop() ?? "com"}`;
  return url.replace(".<tld>", currTLD);
};
