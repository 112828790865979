import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";

interface State {
  selectedOrganisationId: number | null;
}

const initialState: State = {
  selectedOrganisationId: null,
};

const businessSettingsSlice = createSlice({
  name: "businessSettingsSlice",
  initialState,
  reducers: {
    setSelectedOrganisationId(state, action: PayloadAction<number | null>) {
      state.selectedOrganisationId = action.payload;
    },
  },
});

export const selectBusinessSettings = (state: RootState) => state.businessSettings;
export default businessSettingsSlice.reducer;
export const { setSelectedOrganisationId } = businessSettingsSlice.actions;
