import { Organisation } from "../types/db";

export const DEFAULT_ORGANISATION_DOMAIN = "my";
const WLA_IGNORED_DOMAINS = ["my-staging", "my-bulbshare-pr-"];

export const getOrganisationDomain = (url: string | undefined): string | undefined => {
  if (url === undefined) {
    return;
  }
  const regex = /^http[s]?:\/\/(local[.])?(.*?)([.]bulbshare[.](com|dev))/;
  const regexArr = regex.exec(url);
  if (!regexArr) {
    return;
  }
  const organisationDomain = regexArr[2];

  const isIgnoredDomain = WLA_IGNORED_DOMAINS.some((ignoredDomain) =>
    organisationDomain.startsWith(ignoredDomain)
  );
  if (isIgnoredDomain) {
    return;
  }

  return organisationDomain;
};

export const getIsWlaApp = (url: string | undefined): boolean => {
  const organisationDomain = getOrganisationDomain(url);
  return !!organisationDomain && organisationDomain !== DEFAULT_ORGANISATION_DOMAIN;
};

export const getOrganisationKeyValue = <K extends keyof Organisation>(
  organisation: Organisation | undefined,
  key?: K
): Organisation[K] | undefined => {
  if (!key || !organisation) {
    return;
  }
  return organisation[key];
};
