import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ErrorState } from "../../types/state";
import { RootState } from "../../store";

const initialState = {} as ErrorState;

const errorsSlice = createSlice({
  name: "errors",
  initialState,
  reducers: {
    setErrors(state, action: PayloadAction<ErrorState>) {
      state = action.payload;
      return state;
    },
  },
});

export default errorsSlice.reducer;
export const { setErrors } = errorsSlice.actions;
export const selectErrors = (state: RootState) => state.errors;
