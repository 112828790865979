import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import verifyJWT from "#apis/user/verifyJWT";
import { UserProfile, UserState } from "../../types/state";
import { Cookies } from "../../types/types";
import { RootState } from "../../store";
import { ProfileDetails } from "../../types/db";

const initialState: UserState = {
  profile: {},
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setProfile(state, action: PayloadAction<UserProfile>) {
      state.profile = action.payload;
      state.updatedAt = Date.now();
      return state;
    },
    updateProfileDetails(
      state,
      action: PayloadAction<{ [Key in keyof ProfileDetails]?: ProfileDetails[Key] }>
    ) {
      state.profile = { ...state.profile, ...action.payload };
      state.updatedAt = Date.now();
      return state;
    },
    setStatus(
      state,
      action: PayloadAction<{
        verified: boolean;
        token?: string;
      }>
    ) {
      state.isLoggedIn = action.payload.verified;
      state.token = action.payload.token;
      return state;
    },
    setLocale(state, action: PayloadAction<string>) {
      state.profile.locale = action.payload;
      return state;
    },
  },
});

export const updateUserStatus = createAsyncThunk<void, Cookies | undefined>(
  "user/status",
  async (cookie, thunkAPI) => {
    const jwtRes = await verifyJWT({ cookie });

    const verified = !!jwtRes?.data?.verified;
    thunkAPI.dispatch(
      setStatus({
        verified,
        token: cookie?.user,
      })
    );
  }
);

export const selectUser = (state: RootState) => state.user;
export default userSlice.reducer;
export const { setProfile, setStatus, updateProfileDetails } = userSlice.actions;
