export namespace TransformUrlParam {
  type PrefixType = "brief" | "brand";

  const ensurePrefix = (ref: string, type: PrefixType) => {
    if (!ref) {
      return "";
    }
    const prefix = `${type}-`;
    if (ref.startsWith(prefix)) {
      return ref;
    }
    return `${prefix}${ref}`;
  };

  export const brief = (ref: string) => {
    return ensurePrefix(ref, "brief");
  };

  export const channel = (ref: string) => {
    return ensurePrefix(ref, "brand");
  };
}
