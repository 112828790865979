import { CHANNELS_INITIAL_COUNT } from "#constants/channel";
import { createSlice } from "@reduxjs/toolkit";

interface NavigationState {
  myChannelsLimit: number;
  followedChannelsLimit: number;
}

const initialState: NavigationState = {
  myChannelsLimit: CHANNELS_INITIAL_COUNT,
  followedChannelsLimit: CHANNELS_INITIAL_COUNT,
};

const navigationSlice = createSlice({
  name: "navigation",
  initialState,
  reducers: {
    setMyChannelsLimit: (state, action) => {
      state.myChannelsLimit = action.payload;
    },
    setFollowedChannelsLimit: (state, action) => {
      state.followedChannelsLimit = action.payload;
    },
  },
});

export const { setMyChannelsLimit, setFollowedChannelsLimit } = navigationSlice.actions;
export const selectNavigation = (state: { navigation: NavigationState }) =>
  state.navigation;

export default navigationSlice.reducer;
