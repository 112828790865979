import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { RootState } from "../../store";
import { locales } from "#shared/types";

const initialState = "en" as keyof typeof locales;

const localeSlice = createSlice({
  name: "locale",
  initialState,
  reducers: {
    setLocale(state, action: PayloadAction<keyof typeof locales>) {
      state = action.payload;
      return state;
    },
  },
});

export const selectLocale = (state: RootState) => state.locale;
export default localeSlice.reducer;
export const { setLocale } = localeSlice.actions;
