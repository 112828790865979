import { Profile, ProfileDetails } from "../types/db";
import { UserProfile } from "../types/state";

export default (profile?: UserProfile | Profile | ProfileDetails | null) =>
  !!(
    profile &&
    profile.gender &&
    profile.birthday &&
    profile.countryId &&
    profile.countryId > 0 &&
    profile.displayName
  );
