export interface HttpApiCallErrorType extends Error {
  url: string;
  statusCode: number;
  reason: string;
  errorDetails: APIErrorDetails | undefined;
}

export type APIErrorDetails = {
  code: number;
  data: { error_type: string; error_details: string };
  debug: string;
};

export default class HttpApiCallError extends Error {
  statusCode = 0;
  url = "";
  reason = "";
  errorDetails: APIErrorDetails | undefined = undefined;
  constructor(url: string, message: string, statusCode: number) {
    super(message);
    this.url = url;
    this.reason = message;
    this.statusCode = statusCode;
    this.stack = new Error().stack;
    this.name = message;
  }

  public setErrorDetails(errorDetails: APIErrorDetails | undefined) {
    this.errorDetails = errorDetails;
  }
}
