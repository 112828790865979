import HttpApiCallError from "../errors";

async function http<T>(path: string, config: RequestInit): Promise<T> {
    const request = new Request(path, config);
    const response = await fetch(request);

    if (!response.ok) {
        const errorDetails = await response.json().catch();
        const error = new HttpApiCallError(response.url, response.statusText, response.status);
        error.setErrorDetails(errorDetails);
        throw error;
    }

    const contentType = response.headers.get("content-type");
    if (contentType && contentType.indexOf("application/json") !== -1) {
        return response.json();
    }
    // @ts-expect-error response was text
    return response.text();
}

async function get<T>(path: string, config?: RequestInit): Promise<T> {
    const init = { method: "get", ...config };
    return await http<T>(path, init);
}

async function del<T>(path: string, config?: RequestInit): Promise<T> {
    const init = { method: "delete", ...config };
    return await http<T>(path, init);
}

async function post<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
    const init = { method: "post", body: JSON.stringify(body), ...config };
    return await http<U>(path, init);
}

async function put<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
    const init = { method: "put", body: JSON.stringify(body), ...config };
    return await http<U>(path, init);
}

async function patch<T, U>(path: string, body: T, config?: RequestInit): Promise<U> {
    const init = { method: "patch", body: JSON.stringify(body), ...config };
    return await http<U>(path, init);
}

export { get, post, del, put, patch };
