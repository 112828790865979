import { Location } from "react-router";
import hasProfileDetails from "../../utils/hasProfileDetails";
import { isProtectedPage } from "../../utils";
import { ProfileDetails } from "../../types/db";
import { UserProfile } from "#customTypes/state";

function getRedirectURL({ pathname, search }: Location): string {
  if ((!pathname || pathname === "/") && !search) return "";
  const queryParams = JSON.stringify(search).replace(/"/g, "");
  return `?redirect=${pathname}${queryParams}`;
}

function buildURLFromRedirectQuery(redirectParam: string | null) {
  if (!redirectParam) {
    return "/";
  }
  const redirects = redirectParam.split(",");
  let url = redirects.shift();
  if (redirects.length) {
    const symbol = url?.includes("?") ? "&" : "?";
    url = `${url}${symbol}redirect=${redirects?.join(",")}`;
  }

  return url;
}

function getChannelUrl(channelRef?: string) {
  if (!channelRef) {
    return "";
  }

  const formattedRef = channelRef.replace("brand-", "");
  return `/channel/${formattedRef}`;
}

type UsersWithIncompleteProfileVisitingPrivateRoute = {
  routePath: string;
  profile: UserProfile | ProfileDetails | undefined | null;
};
const isUserWithIncompleteProfileVisitingPrivateRoute = ({
  profile,
  routePath,
}: UsersWithIncompleteProfileVisitingPrivateRoute) =>
  !hasProfileDetails(profile) &&
  isProtectedPage(routePath) &&
  !routePath.includes("screener") &&
  !routePath.includes("/signin/profile");

export {
  getRedirectURL,
  buildURLFromRedirectQuery,
  isUserWithIncompleteProfileVisitingPrivateRoute,
  getChannelUrl,
};
