import { fetchBaseQuery } from "@reduxjs/toolkit/query";
import { RootState } from "../../store";
import { GOD_VIEW_HEADER, WLA_HEADER } from "#constants/common";

interface Options {
  baseUrl: string;
}

export const appBaseQuery = ({ baseUrl }: Options) => {
  return fetchBaseQuery({
    baseUrl,
    prepareHeaders: (headers, { getState }) => {
      const state = getState() as RootState;

      const organisation = state.wlaOrganisation.organisation;

      if (organisation) {
        headers.set(WLA_HEADER, organisation.organisationId.toString());
      }

      if (state.godView.selectedOrganisation?.id) {
        headers.set(GOD_VIEW_HEADER, state.godView.selectedOrganisation.id.toString());
      }

      return headers;
    },
  });
};
