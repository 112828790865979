import { AxiosResponse } from "axios";
import { PresignedUrlData } from "../../types/types";
import { client } from "#apis/axios";

export enum PresignedUrlType {
  OrganisationLogo = "/community/organisations/generate-logo-presigned-url",
  ChannelLogo = "/community/channels/generate-logo-presigned-url",
  ChannelBanner = "/community/channels/generate-banner-presigned-url",
  BriefCoverPhoto = "/community/brief-builder/draft/generate-cover-photo-presigned-url",
  BriefCoverVideo = "/community/brief-builder/draft/generate-cover-video-presigned-url",
  BriefContentPhoto = "/community/brief-builder/draft/generate-builder-media-presigned-url",
}

interface RequestOptions {
  type: PresignedUrlType;
  contentType?: string;
  body: Record<string, any>;
}

async function generatePresignedUrl<T = PresignedUrlData>({
  type,
  contentType = "image/png",
  body,
}: RequestOptions): Promise<T | null> {
  try {
    const { data } = await client.post<any, AxiosResponse<T>>(type, {
      ContentType: contentType,
      ...body,
    });

    if (!data) {
      throw new Error("No data received");
    }

    return data;
  } catch (e) {
    console.error("Error when generating presigned URL", e);
  }

  return null;
}

export default generatePresignedUrl;
