import { GOD_VIEW_HEADER, SOURCE_DOMAIN_HEADER, WLA_HEADER } from "#constants/common";
import env from "#env";
import { DEFAULT_ORGANISATION_DOMAIN } from "#utils/organisation";
import axios from "axios";

export const client = axios.create({
  baseURL: env.VITE_API_BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
  timeout: 10000, // 10 seconds
  withCredentials: true,
});

export const configureAxiosGodViewHeader = (godViewId?: number) => {
  client.defaults.headers[GOD_VIEW_HEADER] = godViewId?.toString() || null;
};

export const configureAxiosWlaHeaders = (wlaId?: number, domain?: string) => {
  const origin = parseFullUrl(env.VITE_BASE_URL_UI);

  client.defaults.headers[WLA_HEADER] = wlaId?.toString() || null;
  client.defaults.headers[SOURCE_DOMAIN_HEADER] = domain
    ? origin.replace(DEFAULT_ORGANISATION_DOMAIN, domain)
    : null;
};

const parseFullUrl = (url: string) => {
  return url.replace(/(https?:\/\/|:3000|local\.)/g, "").replace(/\/.*/, "");
};
