import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { RootState } from "../../store";
import { DEEP_LINK_INVITE } from "#constants/common";

const initialState = null as string | null;

const joinBriefIdSlice = createSlice({
  name: "joinBriefIdSlice",
  initialState,
  reducers: {
    setJoinBriefId(_state, action: PayloadAction<string | null>) {
      if (action.payload === DEEP_LINK_INVITE) {
        /* reset state if deepLink is an invite */
        return null;
      }
      return action.payload;
    },
  },
});

export const selectJoinBriefId = (state: RootState) => state.joinBriefId;
export default joinBriefIdSlice.reducer;
export const { setJoinBriefId } = joinBriefIdSlice.actions;
