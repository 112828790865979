import { PresignedUrlData } from "../../types/types";

interface Request {
  data: PresignedUrlData;
  contentType?: string;
  file: File;
}

export default async function uploadPresignedFile({
  data,
  contentType = "image/png",
  file,
}: Request): Promise<boolean> {
  const formData = new FormData();

  Object.keys(data.fields).forEach((key) => {
    formData.append(key, data.fields[key]);
  });

  formData.append("Content-Type", contentType);
  formData.append("file", file);

  try {
    const response = await fetch(data.url, {
      method: "POST",
      body: formData,
    });

    if (response.ok) {
      return true;
    }
  } catch (error) {
    console.error(error);
    throw new Error("Error uploading file");
  }

  return false;
}
