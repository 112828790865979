import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../../store";

const downloadModalSlice = createSlice({
  name: "downloadModalSlice",
  initialState: false,
  reducers: {
    setDownloadModal(state, action) {
      return action.payload;
    },
  },
});

export const selectDownloadModal = (state: RootState) => state.isDownloadModalOpen;
export default downloadModalSlice.reducer;
export const { setDownloadModal } = downloadModalSlice.actions;
