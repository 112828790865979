import { isBrowser } from "../../../utils/browser.utils";
import { Cookies } from "../../../types";

export function getHeaders(cookie?: Cookies): HeadersInit | undefined {
  const headers: HeadersInit = { "Content-Type": "application/json" };

  // on SSR, add cookie if user is logged in
  if (!isBrowser && cookie?.user) {
    headers["Cookie"] = `user=${cookie.user}`;
  }

  return headers;
}
